/*PARTNERS SECTION*/
.partners {
  overflow: hidden;
  text-align: center;
  padding: 0px 0;
  animation: slideInUp 2s;
  margin-top: 50px;
}
.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
}
.slider .slide {
  margin: 0 50px; /* Adjust the value as per your desired spacing */
}
.slide-track {
  display: flex;
  width: calc(250px * 18);
  animation: scroll 40s linear infinite;
}
.list{
  align-self: baseline;
    padding-top: 2em;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
.slide {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}
.img {
  width: 100%;
  transition: transform 1%;
}
img:hover {
  transform: translateZ(20px);
}
/*CAROSELS SHADOW*/
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotate(180deg);
}
/* SERVICES SECTION*/
.services {
  padding: 2%;
  display: flex;
  flex-direction: column;
  font-family: "Ember";
}
.services h1 {
  text-align: left;
}
.payroll-services {
  padding: 2%;
  display: flex;
  flex-direction: column;
  font-family: "Ember";
  margin:-85px 0px 0px 0px 
}
.payroll-services h1 {
  text-align: center;
}

.content,
.service__content {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.content {
  justify-content: center;
  align-items: center;
}
.content ~ :nth-of-type(even) {
  flex-direction: row-reverse;
}
/* .service__content :nth-child(even){
    flex-direction:row;
} */
.service_info,
.testimonials {
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
}
.service_info {
  max-width: 450px;
  padding: 0% 4%;
  animation:slideInLeft 1s;
}
/*TESTIMONIALS SETION*/

.card {
  max-width: 400px;
  border-bottom: 2px solid gray;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.bg {
  padding: 30px;
  background-image: linear-gradient(#1c4792, #1e6069);

  border-radius: 16px 16px 0 0;
}
.details {
  padding: 30px;
}
.profile {
  padding: 40px 20px 10px 20px;
  display: flex;
  justify-content: space-evenly;
}
.review {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 3%;
  justify-content: space-evenly;
}

@media only screen and (max-width: 900px) {
  .content {
    flex-direction: column-reverse;
  }
  .partners {
    padding: 20px 0 40px 0;
  }
  .list{
    padding-top: 1em;
}
}

/*SWIPER CSS */
/* .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
} */
/* MOTION REACT SLIDER */
/* 
.item{
  width: 200px;
    padding: 40px;

}
.item img{
  pointer-events: none;
  width:100%;
  height:100%;
  border-radius:2rem;
}
.inner-carousel{
  display:flex;
  background-color:rgb(115, 210, 219);
    padding:12px;
}
.carousel{
  cursor:grab;
  overflow:hidden;
  background-color:pink;
} */


@media (max-width: 480px) {
  .payroll-services h1 {
      font-size: 35px !important; 
  }
}

@media (max-width: 480px) {
  .cards h1 {
    font-size: 26px !important;
    margin-top: 75px !important;
  }
}

